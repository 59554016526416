import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@lib/shared/services/toast.service';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
@Sentry.TraceClass({ name: 'ResetPasswordComponent' })
export class ResetPasswordComponent {
  private oobCode = this.activatedActivated.snapshot.queryParams['oobCode'];
  public form: UntypedFormGroup;
  public submitted = false;

  constructor(
    private activatedActivated: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private firebaseAuth: AngularFireAuth,
    private toastService: ToastService,
  ) {
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
          ],
        ],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      },
      { validators: this.checkPasswords },
    );
  }

  private checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  };

  public async onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      try {
        await this.firebaseAuth.confirmPasswordReset(this.oobCode, this.form.value['password']);

        await this.toastService.showDissmissableToast(
          'Your password has been reset. Please log in again either on the app or on the web.',
          'bag-check-outline',
          'success',
        );
      } catch (error) {
        this.toastService.showDissmissableErrorToast(`Error: ${error.code} - ${error.message}`);
      }
    }
  }
}
