import { Component } from '@angular/core';
import { CredentialsService } from '@lib/core';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
@Sentry.TraceClass({ name: 'ShellComponent' })
export class ShellComponent {
  constructor(public credentialsService: CredentialsService) {}
}
