import { Component } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { environment } from '@lib/environments/environment';
import { addIcons } from 'ionicons';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
@Sentry.TraceClass({ name: 'VerifyEmailComponent' })
export class VerifyEmailComponent {
  private userId: string;
  private verifySub: Subscription = new Subscription();
  public state: 'loading' | 'successful' | 'already-complete' | 'error' = 'loading';
  public successful = false;
  public appName: string = environment.appType;

  constructor(
    private route: ActivatedRoute,
    private fns: AngularFireFunctions,
  ) {
    addIcons({ checkmarkCircleOutline, closeCircleOutline });

    this.userId = this.route.snapshot.params['id'];

    const verifyEmailCall = this.fns.httpsCallable('verifyEmail');
    const verifyEmail = verifyEmailCall(this.userId);
    this.verifySub = verifyEmail.subscribe({
      next: () => {
        this.successful = true;
        this.state = 'successful';
        this.verifySub.unsubscribe();
      },
      error: (error) => {
        if (error.code === 'functions/invalid-argument') {
          this.state = 'already-complete';
        } else {
          this.state = 'error';
        }

        console.error('error', error);
        this.verifySub.unsubscribe();
      },
    });
  }
}
