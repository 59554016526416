<ion-content no-bounce>
  <div class="login-background"></div>
  <ion-grid no-padding>
    <ion-row justify-content-center no-padding style="justify-content: center">
      <ion-col size-sm="8" size-lg="6" size-xl="4" align-self-center no-padding>
        <div class="login-header" class="ion-text-center">
          <img class="logo" src="assets/pe-pro-logo.png" alt="PE Pro Logo" />
        </div>
        <div class="login-container-wrapper">
          <div class="login-spinner-wrapper" *ngIf="state === 'loading'; else messages">
            <ion-spinner class="primary-spinner"></ion-spinner>
          </div>
          <ng-template #messages>
            <div class="ion-text-center">
              <ion-icon
                size="large"
                [color]="successful ? 'success' : 'danger'"
                [name]="successful ? 'checkmark-circle-outline' : 'close-circle-outline'"
              ></ion-icon>
              <ng-container *ngIf="state === 'successful'">
                <h1>Thank you for verifying your email address!</h1>
                <p>You can now proceed to the app.</p>
                <ion-button color="primary" routerLink="/login">Go to Login</ion-button>
                <p>If you've never signed in, then tap the 'Don't know your password' button to get started.</p>
              </ng-container>
              <ng-container *ngIf="state === 'already-complete'">
                <h1>This email address is already verified!</h1>
                <p>You can now proceed to the app.</p>
                <ion-button color="primary" routerLink="/login">Go to Login</ion-button>
              </ng-container>
              <ng-container *ngIf="state === 'error'">
                <h1>Something has gone wrong!</h1>
                <p>
                  Please email us at
                  <a [href]="'mailto:enquiries@' + appName + 'proapp.com'">enquiries&#64;{{ appName }}proapp.com</a>.
                </p>
              </ng-container>
            </div>
          </ng-template>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
