import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AuthenticationService } from '@lib/core';
import { CredentialsService } from '@lib/core/authentication/credentials.service';
import { ToastService } from '@lib/shared/services/toast.service';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
})
@Sentry.TraceClass({ name: 'LoginFormComponent' })
export class LoginFormComponent {
  public loginForm: UntypedFormGroup;
  public isIos: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private toastService: ToastService,
    public credentialsService: CredentialsService,
    private platform: Platform,
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.isIos = this.platform.is('ios');
  }

  async login() {
    this.authenticationService.nextLoading(true);
    try {
      await this.authenticationService.login(this.loginForm.value);
      this.loginForm.markAsPristine();
      await this.router.navigate([this.route.snapshot.queryParams.redirect || '/tabs/home'], { replaceUrl: true });
      this.authenticationService.nextLoading(false);
    } catch (error: any) {
      console.error(error);
      this.authenticationService.nextLoading(false);
      this.toastService.showDissmissableErrorToast(`Error: ${error.code} - ${error.message}`);
    }
  }

  public showResetPassword() {
    this.authenticationService.nextResetPassword(true);
  }
}
