import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@lib/shared/services/toast.service';
import { AuthenticationService } from 'lib/core';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-licence-expired',
  templateUrl: './licence-expired.component.html',
  styleUrls: ['./licence-expired.component.scss'],
})
@Sentry.TraceClass({ name: 'LicenceExpiredComponent' })
export class LicenceExpiredComponent {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  async logout() {
    try {
      const loginPage = await this.router.navigate(['/login']);
      if (loginPage) {
        await this.authenticationService.logout();
      }
    } catch (error) {
      console.error(error);
      this.toastService.showDissmissableErrorToast('Unable log out. Please try again.');
    }
  }
}
