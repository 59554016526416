import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from '@lib/shared/services/toast.service';
import { CredentialsService } from 'lib/core';
import { RegisterService } from 'lib/core/register.service';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-create-licence',
  templateUrl: './create-licence.component.html',
  styleUrls: ['./create-licence.component.scss'],
})
@Sentry.TraceClass({ name: 'CreateLicenceComponent' })
export class CreateLicenceComponent {
  public form: UntypedFormGroup;
  public submitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private registerService: RegisterService,
    private credentialsService: CredentialsService,
    private toastService: ToastService,
  ) {
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      company: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      address3: [''],
      townCity: ['', Validators.required],
      postcode: ['', Validators.required],
      phone: ['', Validators.required],
      howHeard: ['', Validators.required],
      jobTitle: ['', Validators.required],
      howHeardOther: [''],
    });
    this.addValidator();
  }

  addValidator(): void {
    const howHeardOther = this.form.get('howHeardOther');
    this.form.get('howHeard').valueChanges.subscribe({
      next: (value) => {
        howHeardOther.setValidators(value === 'Other' ? [Validators.required] : null);
        howHeardOther.updateValueAndValidity();
      },
      error: (error) => {
        console.error(error);
        this.toastService.showDissmissableErrorToast('Error getting howHeard value.');
      },
    });
  }

  async onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      this.registerService.licence$.next(this.form.value);
      this.registerService.signUpMethod$.next('signUpForm');
      try {
        await this.registerService.createAccount();
        this.credentialsService.getUserData();
        this.router.navigate(['/tabs/home']);
      } catch (error) {
        console.error(error);
        this.toastService.showDissmissableErrorToast('Unable to sign you up. Please try again.');
      }
    }
  }
}
