import { Component, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { Browser } from '@capacitor/browser';
import { CredentialsService } from '@lib/core/authentication/credentials.service';
import { DeviceService } from '@lib/core/device.service';
import { Homepage } from '@lib/shared/models/home.model';
import { ToastService } from '@lib/shared/services/toast.service';
import { Observable, Subscription } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { addIcons } from 'ionicons';
import { logoFacebook, logoInstagram, logoTwitter, logoLinkedin } from 'ionicons/icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', '../../../../../lib/shared/styles/quill.scss'],
})
@Sentry.TraceClass({ name: 'HomeComponent' })
export class HomeComponent implements OnDestroy {
  public home: Observable<Homepage>;
  private homeDoc: AngularFirestoreDocument<Homepage>;
  private homeVid: Subscription = new Subscription();
  public isWeb: boolean = this.deviceService.isWeb;

  constructor(
    private afs: AngularFirestore,
    public sanitizer: DomSanitizer,
    public credentialsService: CredentialsService,
    private deviceService: DeviceService,
    private toastService: ToastService,
  ) {
    this.homeDoc = this.afs.doc<Homepage>(`homepage/homeContent`);
    this.home = this.homeDoc.valueChanges({ idField: 'id' });
    addIcons({ logoFacebook, logoInstagram, logoTwitter, logoLinkedin });
  }

  @Sentry.TraceMethod({ name: 'ngOnDestroy' })
  ngOnDestroy() {
    this.homeVid.unsubscribe();
  }

  async openPage(url: string): Promise<void> {
    try {
      this.deviceService.isWeb ? window.open(url, '_blank') : await Browser.open({ url });
    } catch (error) {
      console.error(error);
      this.toastService.showDissmissableErrorToast('Error opening page');
    }
  }
}
