import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireAnalyticsModule,
  APP_NAME,
  APP_VERSION,
  COLLECTION_ENABLED,
  CONFIG,
  DEBUG_MODE,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { AngularFireRemoteConfigModule, DEFAULTS } from '@angular/fire/compat/remote-config';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/pePro/environment';
import { IonicModule } from '@ionic/angular';
import { LoginModule } from '@lib/login/login.module';
import { SharedModule } from '@lib/shared';
import { TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { Angulartics2Module } from 'angulartics2';
import { CoreModule } from 'lib/core';
import { MomentModule } from 'ngx-moment';
import { QuillModule } from 'ngx-quill';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { LicenceExpiredModule } from '@lib/licence-expired/licence-expired.module';
import { SettingsModule } from '@lib/settings/settings.module';
import { ShellModule } from './pe-pro-shell/pe-pro-shell.module';
import { ShellModule as ShellOutdoorProModule } from '@app/outdoorPro/shell/shell.module';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    TranslateModule.forRoot(),
    IonicModule.forRoot(),
    CoreModule,
    SharedModule,
    ShellModule,
    ShellOutdoorProModule,
    HomeModule,
    SettingsModule,
    LoginModule,
    LicenceExpiredModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    Angulartics2Module.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthGuardModule,
    AngularFireAuthModule,
    AngularFireRemoteConfigModule,
    AngularFireAnalyticsModule,
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    MomentModule,
    Sentry.TraceModule,
    AppRoutingModule,
  ],
  providers: [
    provideIonicAngular(),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ScreenTrackingService,
    UserTrackingService,
    { provide: REGION, useValue: 'europe-west1' },
    {
      provide: CONFIG,
      useValue: {
        allow_ad_personalization_signals: false,
        anonymize_ip: true,
      },
    },
    { provide: APP_NAME, useValue: environment.name },
    { provide: APP_VERSION, useValue: environment.version },
    { provide: COLLECTION_ENABLED, useValue: false },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: DEFAULTS, useValue: {} },
    // {
    //   provide: SETTINGS,
    //   useFactory: () => (isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {}),
    // },
    {
      provide: DEBUG_MODE,
      useFactory: () => isDevMode(),
    },
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
