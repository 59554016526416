import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, CredentialsService } from '@lib/core';
import { environment } from '@lib/environments/environment';
import { Platform } from '@ionic/angular';
import { ToastService } from '@lib/shared/services/toast.service';
import { AlertService } from '@lib/shared/services/alert.service';
import { firstValueFrom } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import * as Sentry from '@sentry/angular';
import { addIcons } from 'ionicons';
import { exitOutline, helpOutline, key, settingsOutline, trashOutline } from 'ionicons/icons';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
@Sentry.TraceClass({ name: 'SettingsComponent' })
export class SettingsComponent {
  public version: string | null = environment.version;
  public appName: string = environment.appType;

  constructor(
    private router: Router,
    private platform: Platform,
    private authenticationService: AuthenticationService,
    public credentialsService: CredentialsService,
    private toastService: ToastService,
    private alertService: AlertService,
    private fns: AngularFireFunctions,
  ) {
    addIcons({ key, settingsOutline, helpOutline, trashOutline, exitOutline });
  }

  get isWeb(): boolean {
    return !this.platform.is('cordova');
  }

  async logout() {
    try {
      const loginPage = await this.router.navigate(['/login']);
      if (loginPage) {
        await this.authenticationService.logout();
      }
    } catch (error) {
      console.error(error);
      this.toastService.showDissmissableErrorToast('Unable log out. Please try again.');
    }
  }

  async deleteUser() {
    const sure = await this.alertService.areYouSureAlert(
      'Are you sure you want to delete your account? This cannot be undone.',
    );
    if (sure) {
      try {
        const token = await firstValueFrom(this.credentialsService.token);
        const data = { uid: this.credentialsService.currentUserId, token };
        const deleteUserFn = this.fns.httpsCallable('deleteUserV2');
        await firstValueFrom(deleteUserFn(data));
        const loginPage = await this.router.navigate(['/login']);
        if (loginPage) {
          this.toastService.showAutoDissmissableErrorToast('User removed', 'checkbox-outline');
        }
      } catch (error) {
        console.error(error);
        this.toastService.showDissmissableErrorToast('Unable to delete user. Please try again.');
      }
    }
  }
}
