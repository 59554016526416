import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@lib/core';
import { CredentialsService } from '@lib/core/authentication/credentials.service';
import { ToastService } from '@lib/shared/services/toast.service';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-logged-in',
  templateUrl: './logged-in.component.html',
})
@Sentry.TraceClass({ name: 'LoggedInComponent' })
export class LoggedInComponent {
  constructor(
    public credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  async logout($event: Event) {
    $event.preventDefault();
    try {
      const loginPage = await this.router.navigate(['/login']);
      if (loginPage) {
        await this.authenticationService.logout();
      }
    } catch (error) {
      console.error(error);
      this.toastService.showDissmissableErrorToast('Unable log out. Please try again.');
    }
  }
}
