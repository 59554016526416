import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { LicenceService } from '@lib/shared/services/licence.service';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { Licence } from '../models/user.model';

@Injectable()
export class LicenceResolver {
  constructor(
    private router: Router,
    private licenceService: LicenceService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<null | Licence> {
    const licenceId = route.paramMap.get('licenceId');
    return this.licenceService
      .getLicenceDocument(licenceId)
      .get()
      .pipe(map((a) => ({ id: a.id, ...a.data() })))
      .pipe(
        take(1),
        catchError((error) => {
          console.error(error);
          this.router.navigate(['/problem-with-the-service']);
          return of(null);
        }),
      );
  }
}
