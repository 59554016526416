import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { CredentialsService } from '@lib/core/authentication/credentials.service';
import { LicenceService } from '@lib/shared/services/licence.service';
import { Licence, LicenceSettings, User } from '@lib/shared/models/user.model';
import { ToastService } from '@lib/shared/services/toast.service';
import { UserService } from '@lib/shared/services/user.service';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-settings-licence',
  templateUrl: './licence.component.html',
  styleUrls: ['./licence.component.scss'],
})
@Sentry.TraceClass({ name: 'SettingsLicenceComponent' })
export class SettingsLicenceComponent {
  public form: UntypedFormGroup;
  public licenceUsers: User[];
  private licence: Licence;
  private licenceSettings: LicenceSettings;
  public loadingUsers: boolean = true;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private licenceService: LicenceService,
    public credentialsService: CredentialsService,
    public userService: UserService,
    public alertController: AlertController,
    private toastService: ToastService,
  ) {
    this.licence = this.route.snapshot.data?.licence;
    this.licenceSettings = this.licence?.settings;
    this.form = this.fb.group({
      allowUsersEditCurriculum: [this.licenceSettings?.allowUsersEditCurriculum],
    });
  }

  async onSubmit(): Promise<void> {
    if (this.form.valid) {
      try {
        await this.licenceService.updateLicenceSettings(this.licence.id, this.form.value as LicenceSettings);
      } catch (error) {
        console.error(error);
        this.toastService.showDissmissableErrorToast("Something's gone wrong. Please try again later.");
      }
    }
  }
}
